export const urls = {
    users: 'users',
    updateDetail: 'user/update-details/',
    changePassword: 'user/change-password/',
    changeTransactionPin: 'user/change-transaction-pin/',
    changeEmail: 'user/change-email-address/',
    updateEmail: 'user/update-email-address/',
    getProfileDetails  : 'user/get-profile-details/',
    deactivateAccount: 'user/deactivate_user/',
    searchBank: 'admin/search-bank/',
    facialVerification: 'user/account_upgrade/',
    updateDocuments  : 'user/update-documents/',
    getCMS: 'cms/get-details/',
    getBankDetails : 'admin/get-bank-details/',
    addCash  : 'payment/create-payment-deposit-withdraw-request/',
    addCashNew : 'payment/add-cash/',
    getAllCurrencies : 'user/get-all-currencies/',
    addBankAccount : 'user/add-bank/',
    getBanks : 'user/get-all-bank-accounts/',
    deleteBank: 'user/delete-user-bank-account/',
    getTransactions : 'payment/get-all-transactions/',
    getBalance : 'payment/get-user-wallet-amount/',
    getSingleTransaction : 'user/get-user-transaction-details/',
    getBankInfo : 'user/get-bank-details/',
    updateBankAccount : 'user/update-bank-account/',
    getFaq : 'cms/get-all-faq/',
    getReferalCode : 'user/get-referral-code/',
    getReferAmount : 'admin/get-referal-amount/',
    bitcoinBalance : 'bitgo/bitcoin_balance/',
    getNotification : 'user/get-all-user-notification/',
    getEarnigs : 'user/user-refer-history-web/',
    getCryptoBalances : 'payment/get-all-crypto-wallet-balances/',
    getCryptoSingleBalance : 'payment/get-user-crypto-wallet-amount/',
    verifyPin : 'user/verify-transaction-pin/',
    getWalletAddress : 'admin/get-wallet-addresses/',
    getDocuments : 'user/get-all-documents/',
    getNotificationCount : 'user/get-all-user-unread-notification-count/',
    readNotifications : 'user/change-user-notification-to-read/',
    twoAuthSend : 'user/change-two-factor-authentication-status/',
    verifyTWOOTP : 'user/two-factor-authentication/',
    changePaymentRestriction : 'user/change-payment-restriction/',
    checkMaintenace : 'admin/get-website-maintenance-status/',
    getQRCode : 'user/get-user-two-factor-authentication-qr-code/',
    sendOtpVerify : 'user/send-phone-number-otp/',
    verifyOtp : 'user/send-phone-number-verify-otp/',
    updateToken : 'user/change-device-token/',
    send_message : 'message/send-message/',
    verifyEmailWithoutLogin : 'user/verify-email-otp/',
    forgotTransactionPin : 'user/forgot-transaction-pin/',
    contactUs : 'cms/create-contact-us/',
    banks : 'admin/get-all-banks/',
    notification : 'user/enable_notification/',
    walletAdd : 'user/get-user-bitgo-wallet-address/',
    transactionGlobal : 'user/get-user-deposit-wallet-transaction/',
    transactionGlobalXlm : 'user/get-user-xlm-deposit-wallet-transaction/',
    transactionGlobalXrp : 'user/get-user-xrp-deposit-wallet-transaction/',
    transactionGlobalTron : 'user/get-user-tron-deposit-wallet-transaction/',
    getallfaq:'cms/get-all-faq/'

};