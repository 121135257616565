import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { environment } from '../../../src/environments/environment';
import { Notify } from "notiflix";
@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    constructor(private router: Router) { }
    canActivate(next: ActivatedRouteSnapshot,) {
        let userInfo = localStorage.getItem(environment.storageKey);
        if ((userInfo != null)) {
            return true;
        } else if (next.routeConfig.path === 'web') {
            return true;
        }
        else {
            this.router.navigate(['/landingpage']);
            Notify.info('Please login to continue.');
        }
    }
}
