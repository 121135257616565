import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './_gurads/auth.guard';
import { LoggedGuard } from './_gurads/logged.guard';
const routes: Routes = [
	{
		path: '',
		loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule)
	},
	{
		path: 'auth',
		loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
		canActivate: [LoggedGuard]
	},
	{
		path: 'web',
		loadChildren: () =>
		  import('./pages/web/web.module').then((m) => m.WebModule),
		  canActivate: [AuthGuard],
	  },

];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
