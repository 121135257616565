import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Notify, Loading, Block, Confirm, Report } from 'notiflix';
import { environment } from 'src/environments/environment';
import { AuthService } from './_services/auth.service';
import { CommonService } from './_services/common.service';
import { urls } from './_services/urls';
import firebase from "firebase/app";
import "firebase/messaging";
import { SwPush, SwUpdate } from '@angular/service-worker';
import { NavigationEnd, Router } from '@angular/router';
@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit, OnInit {
	title = 'Coinwells';
	serviceWorkerAttempt = 0;
	isMaintenace: boolean = false;
	pushMessages:any;
  displayToken: string = "";
  attempts = 0;
	userInfo: any;
	constructor(private _common: CommonService, private _auth: AuthService, public updates: SwUpdate, public push: SwPush,private router: Router) {

		this.router.events.subscribe((evt) => {
			if (!(evt instanceof NavigationEnd)) {
			  return;
			}
			this.checkMaintenance();
		  });
		this.loadFiles();
		this.fetchInfo();
		let currentUser = JSON.parse(localStorage.getItem(environment.storageKey));
        //  if (currentUser && currentUser.token) {
		// 	setInterval(this.loadScript,100)
		
		//  }
		//Check if user is logged in or not.
		// navigator.serviceWorker.register("ngsw-worker.js");
		// firebase.initializeApp(environment.firebaseConfig);
		// const setInt = () => {
		// 	navigator.serviceWorker.getRegistration().then((swr: any) => {
		// 		this.serviceWorkerAttempt++;
		// 		if (swr != undefined) {
		// 			firebase.messaging().useServiceWorker(swr);
		// 			setTimeout(() => {
		// 				this.permitToNotify();
		// 			}, 2000)
		// 		} else {
		// 			if (this.serviceWorkerAttempt > 0 && this.serviceWorkerAttempt < 20) {
		// 				setInt();
		// 			}
		// 		}
		// 	});
		// };
		// setInt();
		// updates.available.subscribe((_) =>
		// 	updates.activateUpdate().then(() => {
		// 		// console.log("reload for update");
		// 		document.location.reload();
		// 	})
		// );
		// push.messages.subscribe((msg) => {
		// 	this._common.updateNotification.next("");
		// 	// console.log("push message", msg);
		// });
		// push.notificationClicks.subscribe((click) => {
		// 	// console.log("notification click", click);
		// });
		// self.addEventListener("notificationclick", function (event: any) {
		// 	// console.log("Not Working");
		// 	event.notification.close();
		// });

		this.checkMaintenance();

		// if(localStorage.getItem('coinswellUserInfo')){
		// 	setInterval(() =>{
		// 		// this._common.globalTransaction();				
		// 		// this._common.globalTransactionTron();
		// 		// this._common.globalTransactionXlm();
		// 		// this._common.globalTransactionXrp();
		// 	},10000)
		// }
	}


	permitToNotify() {
		const messaging = firebase.messaging();
		messaging
		  .requestPermission()
		  .then(() =>
			messaging.getToken().then((token: any) => {
			  this.displayToken = token;
			  localStorage.setItem("fcmDeviceToken", this.displayToken);
			  if (this.displayToken !== "") {
				  if (!(this.userInfo == null || this.userInfo==undefined)) {

					  this._common.post(urls.updateToken, {
						  device_type: 'WEB',
						  device_token: this.displayToken
					  }).subscribe();
					  messaging.onMessage(() => {
					  })
				  }
			  }
			  console.log(this.displayToken);
			})
		  )
		  .catch((err: any) => {
			console.log("Unable to get permission to notify.", err);
		  });
	  }
	  getToken() {
		navigator.serviceWorker.register("ngsw-worker.js");
		firebase.initializeApp(environment.firebaseConfig);
		const setInt = () => {
		  navigator.serviceWorker.getRegistration().then((swr: any) => {
			this.serviceWorkerAttempt++;
			console.log("swr", swr);
			if (swr != undefined) {
			  firebase.messaging().useServiceWorker(swr);
			} else {
			  if (this.serviceWorkerAttempt > 0 && this.serviceWorkerAttempt < 3) {
				setInt();
			  }
			}
		  });
		};
		setInt();
		this.updates.available.subscribe((_) =>
		this.updates.activateUpdate().then(() => {
				// console.log("reload for update");
				document.location.reload();
			})
		);
		this.push.messages.subscribe((msg) => {
			this._common.updateNotification.next("");
			// console.log("push message", msg);
		});
		this.push.notificationClicks.subscribe((click) => {
			// console.log("notification click", click);
		});
		self.addEventListener("notificationclick", function (event: any) {
			// console.log("Not Working");
			event.notification.close();
		});

		this.checkMaintenance();
		setTimeout(() => {
		  this.permitToNotify();
		}, 2000);
	  }
	

	// permitToNotify() {
	// 	const messaging = firebase.messaging();
	// 	messaging.onMessage(() => {
	// 	})
	// }

	listenEvents() {

	}

	fetchInfo() {
		this._common.updateProfileInfo();
		this.fetchCMS();
	}

	loadFiles() {
		Notify.init({
			clickToClose: true,
			position: 'right-bottom',
			fontSize: '15px',
			showOnlyTheLastOne: true,
			messageMaxLength: 50000,
			width: '300px',
			zindex: 999999
		})
		Loading.init({
			svgColor: '#17C2EC'
		})
		Block.init({
			svgColor: '#17C2EC'
		})
		Confirm.init({
			titleColor: "#17C2EC",
			okButtonBackground: "#17C2EC"
		})
		Report.init({
			svgSize: '60px',
			success: {
				svgColor: "#17C2EC",
				titleColor: '#17C2EC',
				buttonBackground: '#17C2EC',
				buttonColor: '#fff'
			}
		})
		Loading.pulse();
	}

	checkMaintenance() { 
		this._common.get(urls.checkMaintenace).subscribe((data) => {
			this.isMaintenace = data.data.maintainance;
		})
	}

	fetchCMS() {
		this._common.getCMS(urls.getCMS).subscribe(() => {
		})
	}

	ngOnInit() {
		this.userInfo = JSON.parse(localStorage.getItem(environment.storageKey));
		console.log('testing');
		
		// if (userInfo != null) {
		// 	setTimeout(() => {
		// 		const messaging = firebase.messaging();
		// 		messaging
		// 			.requestPermission()
		// 			.then(() => {
		// 				messaging.getToken().then((token: any) => {
		// 					this._auth.firebaseToken = token;
		// 					this._common.post(urls.updateToken, {
		// 						device_type: 'WEB',
		// 						device_token: this._auth.firebaseToken
		// 					}).subscribe();
		// 					messaging.onMessage(() => {
		// 					})
		// 				}).catch(() => {
		// 					// Notify.failure("Unable to get permission to notify.");
		// 				})
		// 			})
		// 			.catch(() => {
		// 				// Notify.failure("Unable to get permission to notify.");
		// 			});
		// 	}, 10000);
		// }
this.getToken()
		
			
	}

	ngAfterViewInit() {
		Loading.remove(500);
		document.onclick = (event : any)=>{
			if(event.target.tagName == 'BUTTON') {
				setTimeout(()=>{
					if(document.getElementsByTagName('mat-error').length > 0) {
						var elmnt : any = document.getElementsByTagName('mat-form-field')[0].parentElement; 
						window.scrollTo({
							top :  elmnt.offsetTop 
						});
					}
				},300);
			}
		};
	}

	doScrolling(elementY, duration) { 
		var startingY = window.pageYOffset;
		var diff = elementY - startingY;
		var start;
	  
		// Bootstrap our animation - it will get called right before next frame shall be rendered.
		window.requestAnimationFrame(function step(timestamp) {
		  if (!start) start = timestamp;
		  // Elapsed milliseconds since start of scrolling.
		  var time = timestamp - start;
		  // Get percent of completion in range [0, 1].
		  var percent = Math.min(time / duration, 1);
	  
		  window.scrollTo(0, startingY + diff * percent);
	  
		  // Proceed with animation as long as we wanted it to.
		  if (time < duration) {
			window.requestAnimationFrame(step);
		  }
		})
	  }



	  loadScript() {
		let chatScript = document.createElement("script");
		chatScript.type = "text/javascript";
		chatScript.async = true;
		chatScript.id="ze-snippet"
		chatScript.src = "https://static.zdassets.com/ekr/snippet.js?key=036ce64b-640b-44f7-ae1c-edc2c67e300c";
			document.body.appendChild(chatScript);
		
	}
}
